'use client'

import { Content, Navigation, Site, trackNavigation } from '@generated/snowplow'
import getSharedContext, { trimPayload } from '../getSharedContext'

export default function trackImpression(
  event: Pick<
    Site,
    | 'unit_name'
    | 'unit_location'
    | 'unit_variation'
    | 'event_type'
    | 'event_horizontal_position'
    | 'event_vertical_position'
  > &
    Pick<Content, 'component_id'> &
    Pick<Navigation, 'button_name'>
) {
  if (
    process.env.NEXT_PUBLIC_SNOWPLOW_DISABLED === 'true' ||
    typeof window === 'undefined'
  ) {
    return
  }

  const navigationContext = trimPayload<Navigation>({
    event_type: 'impression',
    button_name: event.button_name,
  })

  const sharedContext = getSharedContext(event)

  trackNavigation({
    ...navigationContext,
    ...sharedContext,
  })
}
