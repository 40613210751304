import { CommonEventProperties } from '@snowplow/browser-tracker'
import { Content, Page, Site } from '@generated/snowplow'
import schemas from './schemas'

export function trimPayload<T extends Record<string, any>>(data: T): T {
  return Object.entries(data).reduce((prev, [k, v]) => {
    if (v === undefined || v === null) {
      return prev
    }

    return {
      ...prev,
      [k]: v,
    }
  }, {} as T)
}

export default function getSharedContext(
  event: Site & Page & Content
): CommonEventProperties<Site & Page & Content> {
  if (typeof window === 'undefined') {
    return {}
  }

  return {
    context: [
      {
        schema: schemas.site,
        data: trimPayload<Site>({
          unit_name: event.unit_name,
          unit_location: event.unit_location,
          unit_variation: event.unit_variation,
          event_horizontal_position: event.event_horizontal_position,
          event_vertical_position: event.event_vertical_position,
        }),
      },
      {
        schema: schemas.content,
        data: trimPayload<Content>({
          component_id: event.component_id,
        }),
      },
      {
        schema: schemas.page,
        data: trimPayload<Page>({
          page_title: process.env.PARTNER_ID!,
          site_section: 'chatbot',
        }),
      },
    ],
  }
}
