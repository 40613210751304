'use client'

import './chat-window.scss'
import { useState, KeyboardEvent, useRef, useEffect } from 'react'
import SvgSend from 'app/components/Svg/SvgSend'
import { Message } from 'ai/react'
import { v4 as uuidv4 } from 'uuid'
import { generateId } from 'ai'
import { EndpointsContext } from 'app/ai/agent'
import { readStreamableValue } from 'ai/rsc'
import SvgUnderstood from 'app/components/Svg/SvgUnderstood'
import { useLocalStorage } from 'app/hooks/useStorage'
import { trackEngagement } from '@generated/snowplow'
import TextArea from '../TextArea/TextArea.client'
import UserChatBubble from '../UserChatBubble/UserChatBubble.client'
import ResponseChatBubble from '../ResponseChatBubble/ResponseChatBubble.client'
import { useActions } from '../AIProvider/AIProvider.client'
import Markdown from '../Markdown.client'
import TypingAnimation from '../TypingAnimation/TypingAnimation'
import PopularQuestions from '../PopularQuestions/PopularQuestions.client'
import { RunnableConfigWithMetadata } from './types'
import ChatModal from '../ChatModal/ChatModal'

const AI_INPUT_LIMIT = 4000

interface IChatWindowProps {
  assistantPrompt?: string
  welcomeMessage?: string
  popularQuestions?: string[]
  disclaimer?: string
  showModal?: boolean
  modalContent?: string
  modalTitle?: string
  modalButton?: string
}

export default function ChatWindow({
  assistantPrompt,
  welcomeMessage,
  popularQuestions,
  showModal,
  disclaimer,
  modalContent,
  modalTitle,
  modalButton,
}: IChatWindowProps) {
  const [messages, setMessages] = useState<Message[]>([])
  const [inputValue, setInputValue] = useState('')
  const { agent } = useActions<typeof EndpointsContext>()
  const [isRetrieving, setIsRetrieving] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [messageResponseIds, setMessageResponseIds] = useState<
    Record<string, string>
  >({})
  const formRef = useRef<HTMLFormElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const messageViewRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const messageView = messageViewRef.current
    if (!messageView) return

    let timeout: NodeJS.Timeout

    const observer = new MutationObserver((mutationList) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const _mutation of mutationList) {
        const lastNode = messageView.lastElementChild
        if (!lastNode) return

        if (timeout) clearTimeout(timeout)

        timeout = setTimeout(() => {
          messageView.scrollTo({
            top:
              messageView.scrollTop + lastNode.getBoundingClientRect().top - 70,
            behavior: 'smooth',
          })
        }, 200)
      }
    })

    observer.observe(messageView, {
      childList: true,
      characterData: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  const onMessageSubmit = async (value?: string) => {
    const input = value || inputValue
    const history = messages
    const messageId = generateId()

    setMessages((prev) => [
      ...prev,
      { id: `user-${messageId}`, role: 'user', content: input },
    ])
    setInputValue('')
    setIsSubmitting(true)
    setIsRetrieving(true)

    const conversationId = uuidv4()
    const responseId = uuidv4()
    const config: RunnableConfigWithMetadata = {
      runId: responseId,
      metadata: {
        response_id: responseId,
        conversation_id: conversationId,
      },
    }

    trackEngagement({
      unit_name: 'conversation',
      unit_location: 'inline',
      component_id: null,
      event_type: 'click',
      submitted_object: 'written_query',
    })

    const { text } = await agent({
      input,
      chat_history: history,
      config,
    })

    let textContent = ''

    // eslint-disable-next-line no-restricted-syntax
    for await (const delta of readStreamableValue(text)) {
      textContent = `${textContent}${delta}`

      if (textContent) {
        setIsSubmitting(false)
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        setMessages((prev) => {
          const response: Message = {
            id: messageId,
            role: 'assistant',
            content: textContent,
          }

          const existingMessageIndex = prev.findIndex((m) => m.id === messageId)
          if (existingMessageIndex !== -1) {
            return [
              ...prev.slice(0, existingMessageIndex),
              response,
              ...prev.slice(existingMessageIndex + 1),
            ]
          }

          return [...prev, response]
        })
      }
    }

    setIsRetrieving(false)
    setMessageResponseIds({
      ...messageResponseIds,
      [messageId]: responseId,
    })
  }

  const onInputKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      formRef.current?.requestSubmit(buttonRef.current)
    }
  }

  const userHasInteracted = messages.length || isRetrieving
  const [isAccepted, setIsAccepted] = useLocalStorage(
    'disclaimer_accepted',
    false
  )

  if (showModal && !isAccepted) {
    return (
      <ChatModal
        onClose={() => {
          setIsAccepted(true)
        }}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalButton={modalButton}
      />
    )
  }

  return (
    <div className='chatWindow' data-testid='chat-window'>
      <div ref={messageViewRef} className='chatWindow__messageView'>
        <ResponseChatBubble
          className={`chatWindow__welcomeMessage
            ${!assistantPrompt && 'chatWindow__welcomeMessage--largeMargin'}`}
          responseId=''
          hideActionBlock
        >
          {welcomeMessage}
        </ResponseChatBubble>

        {assistantPrompt && (
          <ResponseChatBubble
            className='chatWindow__assistantPrompt'
            responseId=''
            hideActionBlock
          >
            {assistantPrompt}
          </ResponseChatBubble>
        )}

        {messages.map((m, index) => {
          if (m.role === 'assistant') {
            return (
              <ResponseChatBubble
                key={m.id}
                responseId={messageResponseIds[m.id]}
                hideActionBlock={index === messages.length - 1 && isRetrieving}
              >
                {m.content}
              </ResponseChatBubble>
            )
          }

          return <UserChatBubble key={m.id}>{m.content}</UserChatBubble>
        })}

        {isSubmitting && <TypingAnimation />}
      </div>
      <div className='chatWindow__bottomSection'>
        {!userHasInteracted && popularQuestions?.length && (
          <PopularQuestions
            onSelect={onMessageSubmit}
            questions={popularQuestions || []}
          />
        )}

        <div>
          <form
            ref={formRef}
            className={`chatWindow__inputForm ${isRetrieving && 'chatWindow__inputForm--disabled'}`}
            onSubmit={(e) => {
              e.preventDefault()
              onMessageSubmit()
            }}
          >
            <TextArea
              className='chatWindow__inputForm__input'
              placeholder='Message the Assistant...'
              maxLength={AI_INPUT_LIMIT}
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
              rows={1}
              autoFit
              disabled={isRetrieving}
              onKeyDown={onInputKeyPress}
            />
            <button
              ref={buttonRef}
              type='submit'
              className='chatWindow__inputForm__button'
              disabled={!inputValue.length || isRetrieving}
              aria-label='Send message'
            >
              <SvgSend className='chatWindow__inputForm__button__icon' />
            </button>
          </form>
          {inputValue.length >= AI_INPUT_LIMIT * 0.8 && (
            <div>{`${inputValue.length}/${AI_INPUT_LIMIT}`}</div>
          )}
        </div>
        <div className='chatWindow__infoContainer'>
          <div className='chatWindow__disclaimer'>
            {disclaimer && <Markdown>{disclaimer}</Markdown>}
          </div>
          <p className='chatWindow__credit'>
            Powered by
            <SvgUnderstood className='chatWindow__credit__logo' />
          </p>
        </div>
      </div>
    </div>
  )
}
