'use client'

import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Site, Content, Navigation } from '@generated/snowplow'
import trackImpression from './trackImpression'

type ITrackingData = Pick<
  Site,
  'unit_name' | 'unit_variation' | 'unit_location'
> &
  Pick<Content, 'component_id'> &
  Pick<Navigation, 'button_name' | 'button_text' | 'link_text'>

export interface ITrackImpressionProps extends ITrackingData {
  children?: React.ReactNode
  className?: string
  as?: string
  dataTestId?: string
}

export default function TrackImpression({
  as = 'div',
  children,
  className,
  dataTestId,
  unit_location,
  unit_name,
  unit_variation,
  component_id,
  button_name,
}: ITrackImpressionProps) {
  const [ref, inView, entry] = useInView({ triggerOnce: true, threshold: 0 })

  useEffect(() => {
    if (inView) {
      const viewportOffset = entry?.target?.getBoundingClientRect()
      trackImpression({
        unit_location,
        unit_name,
        unit_variation,
        component_id,
        button_name,
        event_type: 'impression',
        event_horizontal_position: viewportOffset?.left || window.scrollX,
        event_vertical_position: viewportOffset?.top || window.scrollY,
      })
    }
  }, [
    inView,
    entry?.target,
    unit_location,
    unit_name,
    unit_variation,
    component_id,
    button_name,
  ])

  if (as === 'fragment') {
    return (
      <>
        {children}
        <span ref={ref} />
      </>
    )
  }

  return React.createElement(
    as,
    {
      className,
      ref,
      'data-testid': dataTestId ?? undefined,
    },
    children
  )
}
