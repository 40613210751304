'use client'

import {
  enableActivityTracking,
  newTracker,
  trackPageView,
} from '@snowplow/browser-tracker'
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone'
import { useParams } from 'next/navigation'
import { useEffect } from 'react'
import getSharedContext from '../getSharedContext'

export default function TrackPageView() {
  const param = useParams()

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_SNOWPLOW_DISABLED !== 'true') {
      newTracker('co', process.env.NEXT_PUBLIC_SNOWPLOW_URL!, {
        appId: 'partner-ai-assistant',
        plugins: [TimezonePlugin()],
      })

      enableActivityTracking({ heartbeatDelay: 15, minimumVisitLength: 15 })

      if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
        const message =
          window.location.protocol === 'https:'
            ? '✅ Snowplow initialized'
            : '⚠️ Snowplow initialized but the server is not using HTTPS. Run `yarn dev:secure` to start the server with HTTPS'

        // eslint-disable-next-line no-console
        console.info(
          `%c[Snowplow]%c ${message}.`,
          'color: rgb(120, 120, 120)',
          'color: inherit'
        )
      }
    }
  }, [])

  useEffect(() => {
    trackPageView(
      getSharedContext({
        event_horizontal_position: window.scrollX,
        event_vertical_position: window.scrollY,
      })
    )
  }, [param])

  return null
}
